import LogoPart from "./LogoPart";
import InputSubjectDropdown from "../../components/InputSubjectDropdown";
import styled from "styled-components/native";
import subjectAtom from "../../atoms/subjectAtom";
import { useAtom } from "jotai";
import { useNavigation } from "@react-navigation/native";
import { gql } from "graphql-request";
import useGqlClient from "../../hooks/gqlClient";

export default function SignUp2Screen() {
    const [subject, setSubject] = useAtom(subjectAtom);
    const navigation = useNavigation();
    const { gqlClient } = useGqlClient();

    async function onSelectSubject(value) {
        const newSubjectName = value;
        const response = await gqlClient.request(GQL_SUBJECT, {
            name: newSubjectName,
        });

        setSubject({
            idSubject: response.subject.idSubject,
            name: newSubjectName,
        });
        navigation.navigate("Home");
    }

    return (
        <SignUp2Container>
            <BodyContainer>
                <LogoPart label={"시험 종류를 입력해주세요"} />
                <InputPart>
                    <InputSubjectDropdown
                        items={[
                            { displayName: "한국사", value: "koreanHistory" },
                            { displayName: "컴활", value: "comHwal" },
                        ]}
                        onSelect={(value) => {
                            onSelectSubject(value);
                        }}
                    />
                </InputPart>
            </BodyContainer>
        </SignUp2Container>
    );
}

const GQL_SUBJECT = gql`
    query Subject($name: String!) {
        subject(name: $name) {
            idSubject
            name
        }
    }
`;

const SignUp2Container = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px 32px;

    flex: none;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px 0px;
`;

const BodyContainer = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    flex: none;
    align-self: stretch;
    flex-grow: 1;
    margin: 32px 0px;
`;

const ButtonPart = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    align-self: stretch;
    margin: 32px 0px;
`;

const InputPart = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    height: 44px;

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 32px 0px;
`;
