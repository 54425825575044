import styled from 'styled-components/native';
import { TextInput } from 'react-native';
import React from 'react';

export default function InputTextContainer({ placeholder, onChangeText, secureTextEntry }) {
    return (
        <InputContainer>
            <Content>
                <LeftContent>
                    <InputText
                        onChangeText={(value) => {
                            onChangeText(value);
                        }}
                        placeholder={placeholder}
                        placeholderTextColor={'#e5e5e5'}
                        keyboardType='default'
                        secureTextEntry={secureTextEntry}
                    />
                </LeftContent>
            </Content>
        </InputContainer>
    );
}

const InputContainer = styled.View`
    flex-grow: 0;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
`;

const Content = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    border-bottom-color: #cccccc;
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;

const LeftContent = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 8px 11px 0px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 1;
    margin: 0px 0px;
`;

const RightContent = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 16px 0px 0px;

    width: 33px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 0px 8px;
`;

const InputText = styled.TextInput`
    font-family: 'NanumSquare';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;

    color: black;

    flex: none;
    flex-grow: 1;
    margin: 0px;

    border-width: 0;
    outline-width: 0;
`;
