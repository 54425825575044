import styled from "styled-components/native";
import { TouchableOpacity } from "react-native";
import FavoriteOffSvg from "../assets/favoriteOffSvg";
import { useState } from "react";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import isFavoriteBookmarkedAtom from "../atoms/isFavoriteBookmarkedAtom";
import appbarMessageAtom from "../atoms/appbarMessageAtom";
import { useEffect } from "react";
import { gql } from "graphql-request";
import useGqlClient from "../hooks/gqlClient";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import userAtom from "../atoms/userAtom";
import problemAtom from "../atoms/problemAtom";
import moment from "moment";
import subjectAtom from "../atoms/subjectAtom";

export default function ActionButton({ Label, onPressNext }) {
    const [isFavoriteBookmarked, setIsFavoriteBookmarked] = useAtom(
        isFavoriteBookmarkedAtom
    );
    const { gqlClient } = useGqlClient();
    const [appbarMessage, setAppbarMessage] = useAtom(appbarMessageAtom);
    const resetAppbarMessage = useResetAtom(appbarMessageAtom);
    const resetIsFavoriteBookmarked = useResetAtom(isFavoriteBookmarkedAtom);
    const [user, setUser] = useAtom(userAtom);
    const [problem, setProblem] = useAtom(problemAtom);
    const [subject, setSubject] = useAtom(subjectAtom);
    const navigation = useNavigation();

    useEffect(() => {
        console.log("hello" , isFavoriteBookmarked);
    }, [isFavoriteBookmarked]);

    async function onPressMe() {
        resetAppbarMessage();
        resetIsFavoriteBookmarked();
        await upsertStudyRecord();
        onPressNext();
    }

    // 업서트 하는거
    async function upsertStudyRecord() {
        const date = moment().format("YYYY-MM-DD");
        const response = await gqlClient.request(GQL_UPSERT_STUDY_RECORD, {
            idUser: user.idUser,
            date,
            idSubject: subject.idSubject,
        });

        console.log(response);
    }

    async function onPressFavoriteButtonPress() {
        //여기 작성
        const response = await gqlClient.request(GQL_TOGGLE_BOOKMARK, {
            idUser: user.idUser,
            idProblem: problem.idProblem,
        });
        console.log(response);
        //여기까지

        const newBookmarked = response.toggleBookmark;
        setIsFavoriteBookmarked(newBookmarked);

        if (newBookmarked === false) {
            setAppbarMessage("복습문제에서 해제되었습니다");
        } else {
            setAppbarMessage("복습문제에 추가되었습니다");
        }
    }

    return (
        <ActionButtonContainer>
            <ButtonFavoriteContainer onPress={onPressFavoriteButtonPress}>
                {isFavoriteBookmarked ? (
                    <ButtonFavoriteOn>
                        <FavoriteOffSvg />
                    </ButtonFavoriteOn>
                ) : (
                    <ButtonFavoriteOff>
                        <FavoriteOffSvg />
                    </ButtonFavoriteOff>
                )}
            </ButtonFavoriteContainer>

            <ButtonNext onPress={onPressMe}>
                <ButtonNextText> {Label} </ButtonNextText>
            </ButtonNext>
        </ActionButtonContainer>
    );
}

const ButtonFavoriteContainer = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;

    width: 48px;
    height: 48px;
    left: 0px;
    top: 0px;

    /* 동그라미 그레이2 */

    background: #eeeeee;
    border-radius: 10px;

    /* Inside auto layout */
    flex-grow: 0;
    margin: 0px 8px;
`;

const ActionButtonContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    flex: none;
    align-self: stretch;
    margin: 8px 0px;
`;

const ButtonFavoriteOn = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;

    width: 48px;
    height: 48px;
    left: 0px;
    top: 0px;

    /* 동그라미 레드 */

    background: #ed1c24;
    border-radius: 10px;

    /* Inside auto layout */
    flex-grow: 0;
    margin: 0px 8px 0px 0px;
`;

const ButtonFavoriteOff = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;

    width: 48px;
    height: 48px;
    left: 0px;
    top: 0px;

    /* 동그라미 그레이2 */

    background: #eeeeee;
    border-radius: 10px;

    /* Inside auto layout */
    flex-grow: 0;
    margin: 0px 8px 0px 0px;
`;

const ButtonNext = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;

    background: #333333;
    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 1;
    align-self: stretch;
    margin: 0px 0px;
`;

const ButtonNextText = styled.Text`
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    align-items: center;
    text-align: center;
    letter-spacing: -0.408px;

    /* 동그라미 화이트 */

    color: #ffffff;

    /* Inside auto layout */
    flex-grow: 1;
    margin: 0px 10px;
`;

const GQL_UPSERT_STUDY_RECORD = gql`
    mutation UpsertStudyRecord(
        $idUser: Int!
        $date: String!
        $idSubject: Int!
    ) {
        upsertStudyRecord(idUser: $idUser, date: $date, idSubject: $idSubject) {
            idUser
            date
            idSubject
            numOfProblemsStudied
        }
    }
`;

const GQL_TOGGLE_BOOKMARK = gql`
    mutation ToggleBookmark($idUser: Int!, $idProblem: Int!) {
        toggleBookmark(idUser: $idUser, idProblem: $idProblem)
    }
`;
