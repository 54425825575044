import ResultScreen from "./screens/ScoreScreen/ResultScreen";
import SolveScreen from "./screens/SolveScreen/SolveScreen";
import MentScreen from "./screens/MentScreen/MentScreen";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import WaitingScreen from "./screens/WaitingScreen/WaitingScreen";
import SplashScreen from "./screens/SplashScreen/SplashScreen";
import LoginScreen from "./screens/StartScreen/LoginScreen";
import SignUp1Screen from "./screens/StartScreen/SignUp1Screen";
import SignUp2Screen from "./screens/StartScreen/SignUp2Screen";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import useGqlClient from "./hooks/gqlClient";

const Stack = createNativeStackNavigator();
const config = {
    animation: "spring",
    config: {
        stiffness: 1000,
        damping: 500,
        mass: 3,
        overshootClamping: true,
        restDisplacementThreshold: 0.01,
        restSpeedThreshold: 0.01,
    },
};

export default function App() {
    const [isLoaded, setIsLoaded] = useState(false);
    const { gqlClient, updateAccessToken } = useGqlClient();
    const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

    useEffect(() => {
        const accessTokenInCookie = cookies["accessToken"];
        if (accessTokenInCookie) {
            updateAccessToken(accessTokenInCookie);
        }

        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return null;
    }

    return (
        <NavigationContainer
            theme={{
                ...DefaultTheme,
                colors: {
                    ...DefaultTheme.colors,
                    background: "white",
                },
            }}
        >
            <Stack.Navigator
                initialRouteName="Login"
                screenOptions={{ headerShown: false }}
            >
                <Stack.Screen
                    name="Home"
                    component={HomeScreen}
                    options={{
                        transitionSpec: {
                            open: config,
                            close: config,
                        },
                    }}
                />
                <Stack.Screen
                    name="Solve"
                    component={SolveScreen}
                    options={{
                        transitionSpec: {
                            open: config,
                            close: config,
                        },
                    }}
                />
                <Stack.Screen
                    name="Result"
                    component={ResultScreen}
                    options={{ getureDirection: "horizontal-inverted" }}
                />
                <Stack.Screen name="Waiting" component={WaitingScreen} />
                <Stack.Screen name="Ment" component={MentScreen} />
                <Stack.Screen name="Splash" component={SplashScreen} />

                <Stack.Screen name="Login" component={LoginScreen} />
                <Stack.Screen name="Sign1" component={SignUp1Screen} />
                <Stack.Screen name="Sign2" component={SignUp2Screen} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}
