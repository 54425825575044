import styled from "styled-components/native";
import AppBar from "../../components/AppBar";
import BodyContainer from "./BodyContainer";
import Divider from "./Divider";
import ButtonContainer from "./ButtonContainer";

export default function MentScreen(){
    return(
        <MentOuter>
            <AppBar label={'대단한데요!'} />
            <BodyContainer />
            <Divider />
            <ButtonContainer />
        </MentOuter>
    )
}

const MentOuter = styled.View`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
/* Inside auto layout */

flex: none;
align-self: stretch;
flex-grow: 1;
`