import styled from "styled-components/native";
import RightArrowSvg from "../../assets/RigthArrowSvg";
import MoreSvg from "../../assets/moreSvg";
import { gql } from "graphql-request";
import useGqlClient from "../../hooks/gqlClient";
import subjectAtom from "../../atoms/subjectAtom";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useAtom } from "jotai";

export default function Header() {
    const navigation = useNavigation();
    const [subject, setSubject] = useAtom(subjectAtom);

    return (
        <HeaderOuter>
            <Frame4>
                <TitleContainer>
                    <TitleText>
                        {subject.name === "comHwal" ? "컴활" : "한국사"} 공부중
                    </TitleText>
                    <TitleIcon
                        onPress={() => {
                            navigation.navigate("Sign2");
                        }}
                    >
                        <RightArrowSvg width={16} height={16} />
                    </TitleIcon>
                </TitleContainer>
            </Frame4>
            <MenuContainer>
                <MoreSvg />
            </MenuContainer>
        </HeaderOuter>
    );
}

const HeaderOuter = styled.View`
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;

    background-color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 4px 0px 16px;

    height: 48px;
    left: 0px;
    top: 0px;

    /* Inside auto layout */
`;

const TitleContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 0px 0px;
`;

const MenuContainer = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 48px;

    align-self: stretch;
    flex-grow: 0;
`;

const TitleText = styled.Text`
    /* 본문 / 볼드 */

    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    text-align: center;
    letter-spacing: -0.408px;

    /* 동그라미 블랙 */

    color: #333333;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 0px 0px;
`;

const TitleIcon = styled.TouchableOpacity`
    flex: none;
    flex-grow: 0;
    margin: 0px 0px;

    width: 24px;
    height: 24px;

    align-items: center;
    justify-content: center;
`;

const Frame4 = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    flex: none;
    flex-grow: 1;
    margin: 0px 0px;
`;
