import styled from "styled-components/native";
import { TouchableOpacity, View } from "react-native";

export default function ButtonStrokeContainer({
    sentence,
    onPress = () => {},
}) {
    return (
        <ButtonStroke onPress={onPress}>
            <Label>{sentence}</Label>
        </ButtonStroke>
    );
}

const ButtonStroke = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 20px;

    border: 2px solid #333333;
    /* box-sizing: border-box; */
    border-radius: 10px;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
`;

const Label = styled.Text`
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    align-items: center;
    text-align: center;
    letter-spacing: -0.408px;

    /* 동그라미 블랙 */

    color: #333333;

    /* Inside auto layout */

    flex: none;
    flex-grow: 1;
    margin: 0px 10px;
`;
