import styled from "styled-components/native";
import CorrectAnswer from "../../components/CorrectAnswer";
import ActionButton from "../../components/ActionButton";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

export default function NextButtonGroup() {
  const navigation = useNavigation();
  return (
    <NextButtonOuter>
      <CorrectAnswer />
      <ActionButton
        Label={"다음 문제"}
        onPress={() => {
          navigation.navigate("Solve");
        }}
      />
    </NextButtonOuter>
  );
}

const NextButtonOuter = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  /* Inside auto layout */

  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;
