import ButtonChoice from "../../components/ButtonChoice";
import styled from "styled-components/native";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { atom, useAtom } from "jotai";
import problemAtom from "../../atoms/problemAtom";

export default function ChoiceContainer() {
  const navigation = useNavigation();
  const [problem, setProblem] = useAtom(problemAtom);

  return (
    <ChoiceOuter>
      <FrameLeft>
        <ButtonChoice
          label={"1번"}
          onPress={() => {
            setProblem({ ...problem, choiceOfUser: 1 });
            navigation.navigate("Result");

          }}
        />
        <ButtonChoice
          label={"4번"}
          onPress={() => {
            setProblem({ ...problem, choiceOfUser: 4 });
            navigation.navigate("Result");
          }}
        />
      </FrameLeft>
      <FrameCenter>
        <ButtonChoice
          label={"2번"}
          onPress={() => {
            setProblem({ ...problem, choiceOfUser: 2 });
            navigation.navigate("Result");
          }}
        />
        <ButtonChoice
          label={"5번"}
          onPress={() => {
            setProblem({ ...problem, choiceOfUser: 5 });
            navigation.navigate("Result");
          }}
        />
      </FrameCenter>
      <FrameRight>
        <ButtonChoice
          label={"3번"}
          onPress={() => {
            setProblem({ ...problem, choiceOfUser: 3 });
            navigation.navigate("Result");
          }}
        />
      </FrameRight>
    </ChoiceOuter>
  );
}

const ChoiceOuter = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const FrameLeft = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  gap: 0px;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
`;

const FrameCenter = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  gap: 0px;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
`;

const FrameRight = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  gap: 0px;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
`;
