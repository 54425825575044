import styled from "styled-components/native";
import CancelSvg from "../assets/cancelSvg";

export default function IncorrectAnswer() {
  return (
    <IncorrectAnswerContainer>
      <Frame35>
        <CancelSvg />
      </Frame35>
      <Label>(가) - (나) - (다)</Label>
    </IncorrectAnswerContainer>
  );
}

const IncorrectAnswerContainer = styled.View`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 13px 16px;

/* 동그라미 블랙 */

border: 2px solid #333333;
/* box-sizing: border-box; */
border-radius: 10px;

/* Inside auto layout */

flex: none;
align-self: stretch;
margin: 8px 0px;
`

const Frame35 = styled.View`
display: flex;
flex-direction: row;
align-items: flex-start;
/* Inside auto layout */

flex: none;
margin: 0px 8px;
`

const Label = styled.Text`

/* 소제목 / 볼드 */

font-family: 'NanumSquare';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 21px;
/* identical to box height, or 140% */

align-items: center;
text-align: center;
letter-spacing: -0.32px;

/* 동그라미 블랙 */

color: #333333;


/* Inside auto layout */

flex: none;
flex-grow: 1;
margin: 0px 8px;
`