import LogoPart from "./LogoPart";
import InputTextContainer from "../../components/InputTextContainer";
import styled from "styled-components/native";
import ButtonGray from "../../components/ButtonGray";
import ButtonFill from "../../components/ButtonFill";
import ButtonStroke from "../../components/ButtonStroke";
import ErrorMessage from "../../components/ErrorMessage";
import { useState, useEffect } from "react";
import { gql } from "graphql-request";
import useGqlClient from "../../hooks/gqlClient";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { KeyboardAvoidingView, Platform, ScrollView, View } from "react-native";
import userAtom from "../../atoms/userAtom";
import { useAtom } from "jotai";
import subjectAtom from "../../atoms/subjectAtom";

export default function LoginScreen() {
    const [isUserinfoProvided, setIsUserinfoProvided] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useAtom(userAtom);
    const { gqlClient, updateAccessToken } = useGqlClient();
    const navigation = useNavigation();
    const [subject, setSubject] = useAtom(subjectAtom);

    useEffect(() => {
        console.log(`email: ${email}, password: ${password}`);
        if (email && password) {
            setIsUserinfoProvided(true);
        } else {
            setIsUserinfoProvided(false);
        }
    }, [email, password]);

    async function onPressLoginButton() {
        try {
            const response = await gqlClient.request(GQL_LOGIN, {
                email,
                password,
            });
            updateAccessToken(response.login.token);

            setUser({
                idUser: response.login.user.idUser,
                email: response.login.user.email,
                nickname: response.login.user.nickname,
            });
            navigation.navigate("Sign2");
        } catch (error) {
            //에러상황
            console.log(error);

            if (error.response.errors[0].message === "EMAIL_NOT_EXISTENT") {
                alert("이메일이 없지롱");
            } else if (
                error.response.errors[0].message === "PASSWORD_INCORRECT"
            ) {
                alert(" 비번 까먹었쥬?");
            }
        }
    }

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            style={{ height: "100%" }}
        >
            <ScrollView style={{ flex: 2, alignSelf: "stretch" }}>
                <EnterContainer>
                    <BodyContainer>
                        <LogoPart label={"로그인을 해보자구요리스"} />
                        <InputPart>
                            <InputTextContainer
                                placeholder={"이메일을 입력해주세요"}
                                onChangeText={(value) => setEmail(value)}
                            />
                            <InputTextContainer
                                placeholder={"비밀번호를 입력해주세요"}
                                onChangeText={(value) => setPassword(value)}
                                secureTextEntry={true}
                            />
                            {/* <ErrorMessage Label={""} /> */}
                        </InputPart>
                    </BodyContainer>

                    <ButtonPart>
                        <ButtonStroke
                            sentence={"회원가입"}
                            onPress={() => {
                                navigation.navigate("Sign1");
                            }}
                        />
                        {isUserinfoProvided ? (
                            <ButtonFill
                                sentence={"로그인"}
                                onPress={onPressLoginButton}
                            />
                        ) : (
                            <ButtonGray sentence={"로그인"} />
                        )}
                    </ButtonPart>
                </EnterContainer>
            </ScrollView>
        </KeyboardAvoidingView>
    );
}

const GQL_LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                idUser
                nickname
                email
            }
            token
        }
    }
`;

const EnterContainer = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px 32px;

    flex: none;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px 0px;
`;

const BodyContainer = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    flex: none;
    flex-grow: 1;
    margin: 32px 0px;
    align-self: stretch;
`;

const ButtonPart = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    flex: none;
    flex-grow: 0;
    margin: 32px 0px;
    align-self: stretch;
`;

const InputPart = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    flex: none;
    flex-grow: 0;
    margin: 32px 0px;
    align-self: stretch;
`;
