import styled from "styled-components/native";
import { TouchableOpacity, View } from "react-native";

export default function ButtonGrayContainer({
  sentence,
  onPress = () => {
    console.log(1);
  },
}) {
  return (
    <ButtonGray onPress={onPress}>
      <Label>{sentence}</Label>
    </ButtonGray>
  );
}

const ButtonGray = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  /* 동그라미 그레이2 */

  background: #eeeeee;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
`;

const Label = styled.Text`
  font-family: "NanumSquare";
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */

  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;

  /* 동그라미 그레이3 */

  color: #949494;

  /* Inside auto layout */

  flex: none;
  flex-grow: 1;
  margin: 0px 10px;
`;
