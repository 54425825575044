import styled from "styled-components/native";
import { TouchableOpacity, View } from "react-native";

export default function ButtonFill({
  sentence,
  onPress = () => {
    console.log(1);
  },
}) {
  return (
    <ButtonFillOuter onPress={onPress}>
      <Label>{sentence}</Label>
    </ButtonFillOuter>
  );
}

const ButtonFillOuter = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;

  background: #333333;
  border-radius: 10px;

  align-self: stretch;
  margin: 8px 0px;
`;

const Label = styled.Text`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 22px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;

  color: #ffffff;

  flex-grow: 1;
  margin: 0px 10px;
`;
