import styled from "styled-components/native";
import ButtonFillContainer from "../../components/ButtonFill";
import ButtonStrokeContainer from "../../components/ButtonStroke";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { TouchableOpacity } from "react-native";
import userAtom from "../../atoms/userAtom";

import { useAtom } from "jotai";
import { Dimensions } from "react-native";
import { Text } from "react-native";
import { gql } from "graphql-request";
import useGqlClient from "../../hooks/gqlClient";

import { VictoryBar, VictoryChart, VictoryAxis, LineSegment } from "victory";
import { useEffect, useState } from "react";
import subjectAtom from "../../atoms/subjectAtom";
import moment from "moment";
import studyModeAtom from "../../atoms/studyModeAtom";

export default function BodyContainer() {
    const navigation = useNavigation();
    const [user, setUser] = useAtom(userAtom);
    const [subject, setSubject] = useAtom(subjectAtom);
    const [studyRecords, setStudyRecords] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [studyMode, setStudyMode] = useAtom(studyModeAtom);
    const { gqlClient } = useGqlClient();

    useEffect(() => {
        (async () => {
            const { studyRecords: _studyRecords } = await gqlClient.request(
                GQL_STUDY_RECORDS,
                {
                    idUser: user.idUser,
                    idSubject: subject.idSubject,
                    date: moment().format("YYYY-MM-DD"),
                }
            );

            const studyRecordsWithMMDD = _studyRecords.map((elem, index) => {
                const MMDD = moment(elem.date, "YYYY-MM-DD").format("MMDD");
                return {
                    ...elem,
                    MMDD: MMDD,
                };
            });

            setStudyRecords(studyRecordsWithMMDD);
            setIsLoaded(true);
        })();
    }, []);

    if (!isLoaded) {
        return <Text>loading...</Text>;
    }
    return (
        <BodyOuter>
            <MessageContainer>
                <MainText>
                    {user.nickname}님, 오늘{" "}
                    {studyRecords[studyRecords.length - 1].numOfProblemsStudied}
                    문제 푸셨어요!
                </MainText>
                <SubText>
                    어제는{" "}
                    {studyRecords[studyRecords.length - 2].numOfProblemsStudied}
                    문제 푸셨어요!
                </SubText>
            </MessageContainer>
            {/* 차트차트 */}
            <VictoryChart
                style={{
                    parent: { background: "#f9f9f9" },
                }}
            >
                <VictoryAxis
                    style={{
                        axis: { stroke: "transparent" },
                        ticks: { stroke: "transparent", size: 4 },
                        tickLabels: { fill: "black", fontSize: 15 },
                    }}
                />
                <VictoryBar
                    style={{
                        data: {
                            width: 36,
                            fill: ({ datum }) =>
                                datum.MMDD === moment().format("MMDD")
                                    ? "#ed1c24"
                                    : "#949494",
                        },
                        labels: {
                            fill: "#949494",
                        },
                    }}
                    data={studyRecords}
                    x="MMDD"
                    y="numOfProblemsStudied"
                    cornerRadius={{ bottom: 5, top: 5 }}
                    labels={({ datum }) => `${datum.numOfProblemsStudied}`}
                />
            </VictoryChart>

            <ButtonsContainer>
                <ButtonStrokeContainer
                    sentence={"복습문제 확인"}
                    onPress={() => {
                        setStudyMode("bookmarkedOnly");

                        navigation.navigate("Solve");
                    }}
                />
                <ButtonFillContainer
                    sentence={"다시 문제풀기"}
                    onPress={() => {
                        setStudyMode("normal");
                        navigation.navigate("Solve");
                    }}
                />
            </ButtonsContainer>
        </BodyOuter>
    );
}

const GQL_STUDY_RECORDS = gql`
    query StudyRecords($idUser: Int!, $date: String!, $idSubject: Int!) {
        studyRecords(idUser: $idUser, idSubject: $idSubject, date: $date) {
            date
            numOfProblemsStudied
        }
    }
`;

const BodyOuter = styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 32px;

    align-self: stretch;
    flex-grow: 1;

    background: white;
`;

const MessageContainer = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px 16px 24px;

    align-self: stretch;
    flex-grow: 0;
`;

const ButtonsContainer = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 16px 0px;

    align-self: stretch;
`;

const MainText = styled.Text`
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* or 121% */

    letter-spacing: 0.364px;

    color: #333333;

    align-self: stretch;
    flex-grow: 0;
    margin: 10px 0px;
`;

const SubText = styled.Text`
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;

    letter-spacing: -0.32px;

    color: #333333;

    align-self: stretch;
    flex-grow: 0;
    margin: 10px 0px;
`;
