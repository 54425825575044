import styled from "styled-components/native";
import { ScrollView } from "react-native";
import { Image } from "react-native";

import { useState, useEffect } from "react";
import { Text } from "react-native";

export default function QuestionContainer({ problemUri }) {
  const [problemAspectRatio, setProblemAspectRatio] = useState(1);

  useEffect(() => {
    Image.getSize(problemUri, (width, height) => {
      setProblemAspectRatio(width / height);
    });
  }, [problemUri]);

  if (!problemUri) {
    return <Text>no image uri provided</Text>;
  }

  return (
    <ScrollView style={{ flex: 1, alignSelf: "stretch" }}>
      <QuestionOuter>
        <QuestionFrame>
          <Image
            source={`${problemUri}`}
            style={{
              width: "100%",
              aspectRatio: problemAspectRatio,
            }}
          />
        </QuestionFrame>
      </QuestionOuter>
    </ScrollView>
  );
}

const QuestionOuter = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 16px;

  background: #f9f9f9;

  align-self: stretch;
  flex-grow: 1;
`;

const QuestionFrame = styled.View`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;

  flex: none;
  align-self: stretch;
  margin: 16px 0px;
`;
