import Svg, { Path } from "react-native-svg";
import { View } from "react-native";

export default function LogoSvg({ width = 66, height = 82 }) {
  return (
    <View style={{ height: height, width: width }}>
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 66 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M21.8086 17.8763C28.8333 15.4034 36.4918 15.4034 43.5166 17.8763L53.7236 7.67031C54.5662 6.82758 55.0395 5.68465 55.0394 4.49295C55.0393 3.30125 54.5658 2.1584 53.7231 1.31581C52.8803 0.473218 51.7374 -9.37543e-05 50.5457 1.39297e-08C49.354 9.37821e-05 48.2111 0.473585 47.3686 1.31631L32.6636 16.0203L23.3896 6.74731C22.9723 6.33004 22.477 5.99902 21.9319 5.77317C21.3868 5.54731 20.8025 5.43105 20.2124 5.431C19.6223 5.43096 19.038 5.54713 18.4929 5.7729C17.9477 5.99867 17.4523 6.3296 17.0351 6.74681C16.6178 7.16402 16.2868 7.65934 16.0609 8.20447C15.8351 8.74961 15.7188 9.33388 15.7188 9.92395C15.7187 10.514 15.8349 11.0983 16.0606 11.6435C16.2864 12.1887 16.6174 12.684 17.0346 13.1013L21.8086 17.8763Z"
          fill="#ED1C24"
        />
        <Path
          d="M43.5156 17.8743L39.0156 22.3743L32.6616 28.7273L26.3066 22.3733L21.8066 17.8733C14.5372 20.4346 8.40791 25.4842 4.50274 32.129C0.597579 38.7738 -0.831792 46.5856 0.46745 54.1827C1.76669 61.7798 5.71082 68.6726 11.6022 73.642C17.4936 78.6114 24.9527 81.3372 32.6601 81.3372C40.3674 81.3372 47.8265 78.6114 53.7179 73.642C59.6093 68.6726 63.5534 61.7798 64.8527 54.1827C66.1519 46.5856 64.7226 38.7738 60.8174 32.129C56.9122 25.4842 50.7829 20.4346 43.5136 17.8733L43.5156 17.8743ZM32.6656 68.6163C28.7228 68.6163 24.8686 67.4471 21.5903 65.2567C18.312 63.0662 15.7569 59.9528 14.248 56.3101C12.7392 52.6675 12.3444 48.6592 13.1136 44.7922C13.8828 40.9252 15.7814 37.3731 18.5694 34.5851C21.3574 31.7972 24.9094 29.8986 28.7764 29.1294C32.6435 28.3602 36.6517 28.7549 40.2944 30.2638C43.937 31.7726 47.0504 34.3277 49.2409 37.606C51.4314 40.8843 52.6006 44.7385 52.6006 48.6813C52.5945 53.9665 50.4922 59.0336 46.755 62.7708C43.0178 66.508 37.9508 68.6102 32.6656 68.6163Z"
          fill="#333333"
        />
      </Svg>
    </View>
  );
}
