import LogoPart from "./LogoPart";
import InputTextContainer from "../../components/InputTextContainer";
import styled from "styled-components/native";
import ButtonGray from "../../components/ButtonGray";
import ButtonStroke from "../../components/ButtonStroke";
import ErrorMessage from "../../components/ErrorMessage";
import { gql } from "graphql-request";
import useGqlClient from "../../hooks/gqlClient";
import { useState } from "react";
import { Dimensions } from "react-native";
import userAtom from "../../atoms/userAtom";
import { useAtom } from "jotai";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

export default function SignUp1Screen() {
    const { gqlClient, updateAccessToken } = useGqlClient();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [nickname, setNickname] = useState("");
    const [user, setUser] = useAtom(userAtom);
    const navigation = useNavigation();

    console.log(Dimensions.get("window").height);

    async function onPressSignUp() {
        //TODO check informatios before form submit
        if (!checkPasswordMatch(password, password2)) {
            console.log("비밀번호를 정확히 재입력해주세요");
            return false;
        }

        // console.dir(error);
        try {
            const response = await gqlClient.request(GQL_SIGN_UP, {
                email,
                password,
                nickname,
            });
            updateAccessToken(response.signUp.token);
            setUser({
                idUser: response.signUp.user.idUser,
                email: response.signUp.user.email,
                nickname: response.signUp.user.nickname,
            });
            navigation.navigate("Sign2");
        } catch (error) {
            console.dir(error);

            if (error.response.errors[0].message === "EMAIL_ALREADY_EXISTS") {
                alert("이메일이 이미 존재한다구~");
            }
        }
    }

    function checkPasswordMatch(password, password2) {
        const result = password === password2 ? true : false;
        return result;
    }

    return (
        <SignUp1Container>
            <BodyContainer>
                <LogoPart label={"회원가입을 진행합니다."} />
                <InputPart>
                    <InputTextContainer
                        placeholder={"가입할 이메일을 입력해주세요"}
                        onChangeText={(value) => setEmail(value)}
                    />
                    <InputTextContainer
                        placeholder={"비밀번호를 입력해주세요"}
                        onChangeText={(value) => setPassword(value)}
                    />
                    <InputTextContainer
                        placeholder={"비밀번호를 한 번 더 입력해주세요"}
                        onChangeText={(value) => setPassword2(value)}
                    />
                    <InputTextContainer
                        placeholder={"닉네임을 입력해주세요"}
                        onChangeText={(value) => setNickname(value)}
                    />
                </InputPart>
            </BodyContainer>

            {/* <ButtonPart> */}
            <ButtonGray sentence={"회원가입"} onPress={onPressSignUp} />
            {/* </ButtonPart> */}
        </SignUp1Container>
    );
}

const GQL_SIGN_UP = gql`
    mutation SignUp($email: String!, $password: String!, $nickname: String!) {
        signUp(email: $email, password: $password, nickname: $nickname) {
            user {
                idUser
                email
                nickname
            }
            token
        }
    }
`;

const SignUp1Container = styled.View`
    width: ${() => Dimensions.get("window").width + "px"};
    height: ${() => Dimensions.get("window").height + "px"};

    background: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px 32px 32px;
    row-gap: 32px;
`;

const BodyContainer = styled.View`
    flex-grow: 1;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    row-gap: 32px;
`;

const InputPart = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    flex: none;
    flex-grow: 0;
    align-self: stretch;
    row-gap: 12px;
`;
