import styled from "styled-components/native";
import Divider from "./Divider";
import AppBar from "../../components/AppBar";
import NextButtonGroupIncorrect from "../ScoreScreen/NextButtonGroupIncorrect";
import QuestionContainer from "../../components/QuestionContainer";
import closeSvg from "../../assets/closeSvg";
import appbarMessageAtom from "../../atoms/appbarMessageAtom";
import { useAtom } from "jotai";
import problemAtom from "../../atoms/problemAtom";
import { Dimensions } from "react-native";
import { useEffect } from "react";

export default function ScoreCorrectScreen() {
    const [appbarMessage, setAppbarMessage] = useAtom(appbarMessageAtom);
    const [problem, setProblem] = useAtom(problemAtom);

    useEffect(() => {
        setAppbarMessage("오답입니다!");
        console.log(problem);
    }, []);

    return (
        <ScoreScreenOuter>
            <AppBar SvgIcon={closeSvg} time={"00:21"} />
            <QuestionContainer problemUri={problem.problemUri} />
            <Divider />
            {/* <ExplanationContainer /> */}
            <NextButtonGroupIncorrect />
        </ScoreScreenOuter>
    );
}

const ScoreScreenOuter = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    flex: none;

    align-self: stretch;
    flex-grow: 1;
    margin: 0px 0px;
    height: ${() => Dimensions.get("window").height};
`;
