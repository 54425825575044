import styled from 'styled-components/native';
import * as React from 'react-native';
import LogoSvg from '../../assets/logoSvg';

export default function LogoPart({ label }) {
    return (
        <LogoFrame>
            <LogoSvg />
            <TextFrame>
                <WelcomeText>반가워요!</WelcomeText>
                <InformationText>{label}</InformationText>
            </TextFrame>
        </LogoFrame>
    );
}

const LogoFrame = styled.View`
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;

    row-gap: 16px;
`;

const TextFrame = styled.View`
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    row-gap: 8px;
`;

const WelcomeText = styled.Text`
    font-family: 'NanumSquare';
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height, or 121% */

    text-align: center;
    letter-spacing: 0.364px;

    /* 동그라미 블랙 */

    color: #333333;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 8px 0px;
`;

const InformationText = styled.Text`
    font-family: 'NanumSquare';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    text-align: center;
    letter-spacing: -0.408px;

    /* 동그라미 블랙 */

    color: #333333;

    /* Inside auto layout */

    flex: none;
    flex-grow: 0;
    margin: 8px 0px;
`;
