import styled from "styled-components/native";

export default function Divider() {
  return <DividerContainer />;
}

const DividerContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  height: 16px;

  /* 동그라미 그레이1 */

  background: #f9f9f9;

  /* Inside auto layout */

  flex: none;

  align-self: stretch;
`;
