import { useAtom } from "jotai";
import accessTokenAtom from "../atoms/accessTokenAtom";
import { GraphQLClient } from "graphql-request";
import { useCookies } from "react-cookie";

const endpoint = "https://952iq5sqge.execute-api.ap-northeast-2.amazonaws.com/graphql";
//VS code SSH config or AWS EC2 public IPv4, port는 backend index.js//
const gqlClient = new GraphQLClient(endpoint);

function useGqlClient() {
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);

  gqlClient.setHeaders({ Authorization: `BEARER ${accessToken}` });

  function updateAccessToken(token) {
    setAccessToken(token);
    setCookie("accessToken", token);
  }

  return { gqlClient, updateAccessToken };
}

export default useGqlClient;
