import Header from "./HeaderContainer";
import Body from "./BodyContainer";

export default function HomeScreen() {
  return (
    <>
      <Header />
      <Body />
    </>
  );
}