import styled from "styled-components/native";

export default function BodyContainer() {
  return (
    <BodyOuter>
      <Frame31>
        <HandLogo>👏</HandLogo>
        <Label>
          더 이상 풀 문제가 없어요. 복습문제를 다시 보는건 어떨까요?
        </Label>
      </Frame31>
    </BodyOuter>
  );
}

const BodyOuter = styled.View`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 24px 16px 16px;

/* overflow-y: scroll; */

/* 동그라미 그레이1 */

background: #F9F9F9;

/* Inside auto layout */

flex: none;
align-self: stretch;
flex-grow: 1;
`

const Frame31 = styled.View`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

flex: none;
align-self: stretch;
margin: 16px 0px;
`

const HandLogo = styled.Text`
font-family: 'NanumSquare';
font-style: normal;
font-weight: 800;
font-size: 56px;
line-height: 80px;
/* identical to box height, or 143% */

align-items: center;
text-align: center;
letter-spacing: 0.374px;

color: #000000;


/* Inside auto layout */

flex: none;
align-self: stretch;
`

const Label = styled.Text`
font-family: 'NanumSquare';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 22px;
/* or 129% */

align-items: center;
text-align: center;
letter-spacing: -0.408px;

/* 동그라미 블랙 */

color: #333333;


/* Inside auto layout */

flex: none;
align-self: stretch;
`