import styled from "styled-components/native";
import React from "react";
import ButtonFill from "./ButtonFill";

export default function InputSubjectDropdown({ items, onSelect }) {
  return (
    <InputContainer>
      {items.map((item, index) => {
        return (
          <ButtonFill
            key={`subject_${index}`}
            sentence={item.displayName}
            onPress={() => onSelect(item.value)}
          />
        );
      })}
    </InputContainer>
  );
}

const InputContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  height: 44px;
  left: 0px;
  top: 56px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
  margin: 12px 0px;
  align-self: stretch;
`;
