import styled from "styled-components/native";
import AppBar from "../../components/AppBar";
import QuestionContainer from "../../components/QuestionContainer";
import Divider from "./Divider";
import ChoiceContainer from "./ChoiceContainer";
import CloseSvg from "../../assets/closeSvg";
import { useAtom } from "jotai";
import appbarMessageAtom from "../../atoms/appbarMessageAtom";
import { Dimensions } from "react-native";

import { useEffect, useState } from "react";
import useGqlClient from "../../hooks/gqlClient";
import { gql } from "graphql-request";
import subjectAtom from "../../atoms/subjectAtom";
import problemAtom from "../../atoms/problemAtom";
import userAtom from "../../atoms/userAtom";
import isFavoriteBookmarkedAtom from "../../atoms/isFavoriteBookmarkedAtom";
import studyModeAtom from "../../atoms/studyModeAtom";
import { Text } from "react-native";
import { useResetAtom } from "jotai/utils";
import { useNavigation } from "@react-navigation/native";

export default function SolveScreen() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [appbarMessage, setAppbarMessage] = useAtom(appbarMessageAtom);
    const [subject, setSubject] = useAtom(subjectAtom);
    const [user, setUser] = useAtom(userAtom);
    const [problem, setProblem] = useAtom(problemAtom);
    const resetProblemAtom = useResetAtom(problemAtom);
    const [isFavoriteBookmarked, setIsFavoriteBookmarked] = useAtom(
        isFavoriteBookmarkedAtom
    );
    const navigation = useNavigation();
    const [studyMode, setStudyMode] = useAtom(studyModeAtom);
    const { gqlClient } = useGqlClient();

    useEffect(() => {
        async function fetchData() {
            const { randomProblem } = await gqlClient.request(
                GQL_RANDOM_PROBLEM,
                {
                    idUser: user.idUser,
                    subjectName: subject.name,
                    studyMode,
                }
            );
            if (!randomProblem) {
                navigation.navigate("Home");
                alert("문제가 없습니다.");
                resetProblemAtom();
                return false;
            }
            setProblem(randomProblem);
            
            const { checkBookmark: isBookmarked } = await gqlClient.request(
                GQL_CHECK_BOOKMARK,
                {
                    idUser: user.idUser,
                    idProblem: randomProblem.idProblem,
                }
            );
            setIsFavoriteBookmarked(isBookmarked);

            setIsLoaded(true);
        }

        fetchData();
    }, [problem?.refreshKey]);

    if (!isLoaded) {
        return <Text>loading</Text>;
    }

    if (studyMode === "bookmarkedOnly" && !problem.problemUri) {
        return <Text>북마크한 문제가 없습니다</Text>;
    }

    return (
        <BodyContainer>
            <AppBar SvgIcon={CloseSvg} time={"00:05"} />
            <QuestionContainer problemUri={problem.problemUri} />
            <Divider />
            <ChoiceContainer />
        </BodyContainer>
    );
}

const BodyContainer = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 1;

    height: ${() => Dimensions.get("window").height};
`;

const GQL_RANDOM_PROBLEM = gql`
    query RandomProblem(
        $idUser: Int!
        $subjectName: String!
        $studyMode: String!
    ) {
        randomProblem(
            idUser: $idUser
            subjectName: $subjectName
            studyMode: $studyMode
        ) {
            idProblem
            problemUri
            explanationUri
            numProblem
            answer
            problemSet {
                name
            }
        }
    }
`;

const GQL_CHECK_BOOKMARK = gql`
    query CheckBookmark($idUser: Int!, $idProblem: Int!) {
        checkBookmark(idUser: $idUser, idProblem: $idProblem)
    }
`;
