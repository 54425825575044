import styled from "styled-components/native";
import ButtonStroke from "../../components/ButtonStroke";
import ButtonFill from "../../components/ButtonFill";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

export default function ButtonContainer() {
  const navigation = useNavigation();

  return (
    <ButtonOuter>
      <ButtonStroke sentence={"복습문제 확인"} />
      <ButtonFill
        sentence={"홈으로"}
        onPress={() => {
          navigation.navigate("Home");
        }}
      />
    </ButtonOuter>
  );
}

const ButtonOuter = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  flex: none;
  align-self: stretch;
`;
