import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

const problemAtom = atomWithReset({
    problemUri: "",
    explanationUri: "",
    numProblem: 0,
    answer: 0,
    problemSet: { name: "" },
    choiceOfUser: 0,
    refreshKey: 0,
});

export default problemAtom;
