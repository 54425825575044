import styled from "styled-components/native";
import { TouchableOpacity } from "react-native";

export default function ButtonChoice({
  label,
  onPress = () => {
    console.log(1);
  },
}) {
  return (
    <ButtonChoiceContainer onPress={onPress}>
      <Label>{label}</Label>
    </ButtonChoiceContainer>
  );
}

const ButtonChoiceContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;

  /* 동그라미 블랙 */

  border: 2px solid #333333;
  /* box-sizing: border-box; */
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  align-self: stretch;
  margin: 8px 0px;
`;

const Label = styled.Text`
  /* 소제목 / 볼드 */

  font-family: "NanumSquare";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 140% */

  align-items: center;
  text-align: center;
  letter-spacing: -0.32px;

  /* 동그라미 블랙 */

  color: #333333;

  /* Inside auto layout */

  flex: none;
  flex-grow: 1;
  margin: 0px 10px;
`;
