import Svg, { Path } from "react-native-svg";
import { View } from "react-native";

export default function favoriteOffSvg({
  width = 18,
  height = 17,
  fill = "white",
  stroke = "#333333",
}) {
  return (
    <View style={{ height: height, width: width }}>
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 18 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M8.99995 14.2698L13.15 16.7798C13.91 17.2398 14.8399 16.5598 14.6399 15.6998L13.54 10.9798L17.2099 7.79977C17.8799 7.21977 17.5199 6.11977 16.6399 6.04977L11.81 5.63977L9.91995 1.17977C9.57995 0.369766 8.41995 0.369766 8.07995 1.17977L6.18995 5.62977L1.35995 6.03977C0.479951 6.10977 0.119951 7.20977 0.789951 7.78977L4.45995 10.9698L3.35995 15.6898C3.15995 16.5498 4.08995 17.2298 4.84995 16.7698L8.99995 14.2698Z"
          fill={fill}
          stroke={stroke}
        />
      </Svg>
    </View>
  );
}
