import styled from "styled-components/native";
import * as React from "react-native";
import LogoSvg from "../../assets/logoSvg";

export default function WaitingLogo({ Label }) {
  return (
    <Frame2>
      <Frame3>
        <LogoSvg/>
        <WaitingLabel> {Label} </WaitingLabel>
      </Frame3>
    </Frame2>
  );
}

const Frame2 = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  /* Inside auto layout */

  flex: none;
  align-self: stretch;
  flex-grow: 1;
`;

const Frame3 = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  margin: 10px 0px;
`;

const WaitingLabel = styled.Text`
  font-family: "NanumSquare";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */

  text-align: center;
  letter-spacing: -0.408px;

  /* 동그라미 블랙 */

  color: #333333;

  /* Inside auto layout */

  flex: none;
  margin: 16px 0px;
`;
