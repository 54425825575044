import styled from "styled-components/native";
import CorrectAnswer from "../../components/CorrectAnswer";
import IncorrectAnswer from "../../components/IncorrectAnswer";
import ActionButton from "../../components/ActionButton";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import problemAtom from "../../atoms/problemAtom";
import { useAtom } from "jotai";

export default function NextButtonGroup() {
    const navigation = useNavigation();
    const [problem, setProblem] = useAtom(problemAtom);
    return (
        <NextButtonOuter>
            <CorrectAnswer />
            <IncorrectAnswer />
            <ActionButton
                Label={"다음 문제"}
                onPressNext={() => {
                    console.log(111);

                    setProblem({ ...problem, refreshKey: Math.random() });
                    navigation.navigate("Solve");
                }}
            />
        </NextButtonOuter>
    );
}

const NextButtonOuter = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
`;
