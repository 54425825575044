import Svg, { Path } from "react-native-svg";
import { View } from "react-native";

export default function moreSvg({ width = 8, height = 12 }) {
  return (
    <View style={{ height: height, width: width }}>
      <Svg
        width="100%"
        height="100%"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M1.29006 9.88L5.17006 6L1.29006 2.12C0.900059 1.73 0.900059 1.1 1.29006 0.710003C1.68006 0.320003 2.31006 0.320003 2.70006 0.710003L7.29006 5.3C7.68006 5.69 7.68006 6.32 7.29006 6.71L2.70006 11.3C2.31006 11.69 1.68006 11.69 1.29006 11.3C0.910059 10.91 0.900059 10.27 1.29006 9.88Z"
          fill="black"
        />
      </Svg>
    </View>
  );
}
