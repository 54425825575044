import ScoreCorrectScreen from "./ScoreCorrectScreen";
import ScoreIncorrectScreen from "./ScoreIncorrectScreen";
import { useAtom } from "jotai";
import problemAtom from "../../atoms/problemAtom";

export default function ResultScreen() {
  const [problem, setProblem] = useAtom(problemAtom);

  return problem.choiceOfUser === problem.answer ? (
    <ScoreCorrectScreen />
  ) : (
    <ScoreIncorrectScreen />
  );
}
