import styled from "styled-components/native";
import appbarMessageAtom from "../atoms/appbarMessageAtom";
import { useAtom } from "jotai";
import { TouchableOpacity } from "react-native";
import { NavigationContainer, useNavigation } from "@react-navigation/native";

export default function AppBar({ time, SvgIcon = undefined }) {
    const isSvgIconProvided = SvgIcon !== undefined;
    const [appbarMessage, setAppbarMessage] = useAtom(appbarMessageAtom);

    const navigation = useNavigation();

    return (
        <HeaderOuter>
            {isSvgIconProvided && (
                <Frame29
                    onPress={() => {
                        navigation.navigate("Home");
                    }}
                >
                    <SvgIcon />
                </Frame29>
            )}
            <Frame4>
                <Frame4Text> {appbarMessage} </Frame4Text>
            </Frame4>
            <Frame30>
                {!!time ? <Frame30Text> {time} </Frame30Text> : null}
            </Frame30>
        </HeaderOuter>
    );
}

const HeaderOuter = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;

    height: 48px;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
`;

const Frame4 = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* Inside auto layout */

    flex: none;
    flex-grow: 1;
`;

const Frame29 = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
`;

const Frame30 = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
`;

const Frame4Text = styled.Text`
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    text-align: center;
    letter-spacing: -0.408px;

    /* 동그라미 블랙 */

    color: #333333;

    /* Inside auto layout */

    flex: none;
`;

const Frame30Text = styled.Text`
    font-family: "NanumSquare";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    text-align: center;
    letter-spacing: -0.408px;

    /* 동그라미 블랙 */

    color: #333333;

    /* Inside auto layout */

    flex: none;
`;
